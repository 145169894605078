<template>

  <div class="wrapper">

    <section class="carousel-section ">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

        <div class="carousel-inner">

          <div class="carousel-item active">
            <img class="d-block w-100" src="img/carousel/signup.jpeg">
          </div>

          <div class="carousel-item ">
            <img class="d-block w-100" src="img/carousel/deposit.jpeg">
          </div>

          <div class="carousel-item ">
            <img class="d-block w-100" src="img/carousel/jackpot.jpeg">
          </div>

        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>

    <div>

      <section class="container">

        <!-- Title and Link -->
        <div class="row mt-2 mb-2">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5>Flying High</h5>
            <a @click="goTo('casino')" class="float-right white-txt">All Games <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>

        <!-- First Image (Full Width) -->
        <div class="row justify-content-around">

          <div class="col-sm-4 col-4 col-md-4 crash-game fly-game" data-toggle="modal" data-target="#rocket-man">

            <img loading="lazy" src="img/crashgames/rocketman.png" class="img-fluid rounded border rocket-fastball" alt="sportycrash rocketman">

            <!-- Modal -->
            <div class="modal " id="rocket-man" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

              <div class="modal-dialog modal-dialog-centered" role="document">

                <div class="modal-content" style="background-color: #1c2243">

                  <div class="modal-body">

                    <div style="background-color: #1c2243">

                      <div class="d-flex">
                        <a title="Close" class="modal-close" @click="closeModal">X</a>
                        <h6 class="text-light">Rocket Man</h6>
                      </div>

                      <img loading="lazy" src="img/crashgames/rocketman.png" alt="gameimg" class="ww-100 mb-2"/>

                      <div class="text-center ">

<!--                        <router-link style="display: none" class="btn btn-warning ww-100 mb-2 fun-mode" :to="{name: 'rocketman', params: {IsDemo:1}}">
                          Fun Mode
                        </router-link>-->

                        <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'rocketman', params: {IsDemo:0}}">
                          Play Now
                        </router-link>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

          <div class="col-sm-4 col-4 col-md-4 crash-game fly-game" data-toggle="modal" data-target="#comet">

            <img loading="lazy" src="img/crashgames/jetsafi.png" class="img-fluid rounded border rocket-fastball" alt="sportycrash comet">

            <!-- Modal -->
            <div class="modal " id="comet" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

              <div class="modal-dialog modal-dialog-centered" role="document">

                <div class="modal-content" style="background-color: #1c2243">

                  <div class="modal-body">

                    <div style="background-color: #1c2243">

                      <div class="d-flex">
                        <a title="Close" class="modal-close" @click="closeModal">X</a>
                        <h6 class="text-light">Fast Balls</h6>
                      </div>

                      <img loading="lazy" src="img/crashgames/jetsafi.png" alt="gameimg" class="ww-100 mb-2"/>

                      <div class="text-center ">

                        <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'jetsafi', params: {IsDemo:0}}">
                          Play Now
                        </router-link>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

          <div class="col-sm-6 col-4 col-md-4 crash-game fly-game" data-toggle="modal" data-target="#fastballs">

            <img loading="lazy" src="img/crashgames/fastballs.jpeg" class="img-fluid rounded border rocket-fastball" alt="sportycrash rocketman">

            <!-- Modal -->
            <div class="modal " id="fastballs" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

              <div class="modal-dialog modal-dialog-centered" role="document">

                <div class="modal-content" style="background-color: #1c2243">

                  <div class="modal-body">

                    <div style="background-color: #1c2243">

                      <div class="d-flex">
                        <a title="Close" class="modal-close" @click="closeModal">X</a>
                        <h6 class="text-light">Fast Balls</h6>
                      </div>

                      <img loading="lazy" src="img/crashgames/fastballs.jpeg" alt="gameimg" class="ww-100 mb-2"/>

                      <div class="text-center ">

<!--                        <router-link style="display: none" class="btn btn-warning ww-100 mb-2 fun-mode" :to="{name: 'fastballs', params: {IsDemo:1}}">
                          Fun Mode
                        </router-link>-->

                        <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'fastballs', params: {IsDemo:0}}">
                          Play Now
                        </router-link>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </section>

      <section class="container">
        <div class="row mt-2 mb-2">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5>Just Landed</h5>
            <a @click="goTo('casino')" class="float-right white-txt">All Games <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>

        <div class="row">

          <div v-for="(o, index) in allgames" :key="getKey(index)" class="col-lg-4 col-md-4 col-4 text-center crash-game" data-toggle="modal"
               :data-target="getDivIDWithHash(o.game_id)">

            <a>
              <img loading="lazy" :src="o.image" alt="Avatar" style="max-width: 100%;height: 140px;border-radius: 5px;border:0px;" class="img-fluid rounded border"/>
            </a>

            <!-- Modal -->
            <div class="modal " :id="getDivID(o.game_id)" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">

              <div class="modal-dialog modal-dialog-centered" role="document">

                <div class="modal-content" style="background-color: #1c2243">

                  <div class="modal-body">

                    <div style="background-color: #1c2243">

                      <div class="d-flex">
                        <a title="Close" class="modal-close" @click="closeModal">X</a>
                        <h6 class="text-light">{{ o.game_name }}</h6>
                      </div>

                      <img loading="lazy" :src="o.image" alt="gameimg" class="ww-100 mb-2"/>

                      <div class="text-center ">

                        <!-- <router-link v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames',params: {gameName: o.game_name,launchURL: o.demo_url,launchType: 1,gameID: o.game_id},}">
                          <button class="btn btn-warning ww-100 mb-2"><b>DEMO</b></button>
                        </router-link> -->

<!--                        <router-link class="btn btn-warning ww-100 mb-2 fun-mode" v-show="parseInt(o.has_demo) === 1" :to="{ name: 'smartsoft', params: { IsDemo: 1, GameCategory: o.GameCategory, GameName: o.GameName, FullGameName: o.FullGameName } }">
                          Fun Mode
                        </router-link>-->

                        <router-link class="btn btn-warning ww-100 mb-2" :to="{ name: 'smartsoft', params: { IsDemo: 0, GameCategory: o.GameCategory, GameName: o.GameName, FullGameName: o.FullGameName } }">
                          Play Now
                        </router-link>

                      </div>


                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </section>

      <section class="container">

        <div class="row mt-2 mb-2">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5>Favourites</h5>
            <a @click="goTo('casino')" class="float-right white-txt">All Games <i class="bi bi-chevron-right"></i></a>
          </div>
        </div>

        <div class="row">

          <div v-for="(o, index) in pragmatic" :key="getKey(index)" class="col-lg-4 col-md-4 col-4 text-center crash-game" data-toggle="modal"
               :data-target="getDivIDWithHash(o.game_id)">

            <a>
              <img loading="lazy" :src="getImg(o.image)" alt="Avatar" style="max-width: 100%;height: 140px;border-radius: 5px;border:0px;" class="img-fluid rounded border"/>
            </a>

            <!-- Modal -->
            <div class="modal " :id="getDivID(o.game_id)" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">

              <div class="modal-dialog modal-dialog-centered" role="document">

                <div class="modal-content" style="background-color: #1c2243">

                  <div class="modal-body">

                    <div style="background-color: #1c2243">

                      <div class="d-flex">
                        <a title="Close" class="modal-close" @click="closeModal">X</a>
                        <h6 class="text-light">{{ o.game_name }}</h6>
                      </div>

                      <img loading="lazy" :src="getImg(o.image)" alt="gameimg" class="ww-100 mb-2"/>

                      <div class="text-center ">

<!--                        <router-link class="btn btn-warning ww-100 mb-2 fun-mode" v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames', params: {IsDemo:1, gameName: o.game_name,gameID: o.game_id,launchURL: o.demo_url,launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                          Fun Mode
                        </router-link>-->

                        <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {IsDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                          Play Now
                        </router-link>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </section>

    </div>

  </div>

</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      showButtonsForGame: null,
      allgames: [
        {
          game_id: "FootballX",
          GameName: "FootballX",
          FullGameName: "FootBall X",
          image: "https://storage.googleapis.com/undabet/assets/jetx/FootballX.jpg",
          GameCategory: "GamesMobile",
          has_demo: 1,
        },
        {
          game_id: "JetX",
          GameName: "JetX",
          FullGameName: "Aviator",
          GameIcon: "https://storage.googleapis.com/undabet/assets/jetx/jetx.webp",
          image: "https://storage.googleapis.com/undabet/assets/jetx/jetx.webp",
          GameCategory: "JetXMobile",
          has_demo: 1,
        },
        {
          game_id: "Balloon",
          GameName: "Balloon",
          FullGameName: "Ballon",
          GameIcon: "https://storage.googleapis.com/undabet/assets/jetx/Balloon.webp",
          image: "https://storage.googleapis.com/undabet/assets/jetx/Balloon.webp",
          GameCategory: "GamesMobile",
          has_demo: 1,
        },
        {
          game_id: "CricketX",
          GameName: "CricketX",
          FullGameName: "Cricket X",
          GameIcon: "https://storage.googleapis.com/undabet/assets/jetx/CricketX.webp",
          image: "https://storage.googleapis.com/undabet/assets/jetx/CricketX.webp",
          GameCategory: "GamesMobile",
          has_demo: 1,
        },
        {
          game_id: "PlinkoX",
          GameName: "PlinkoX",
          FullGameName: "Plinko X",
          GameIcon: "https://storage.googleapis.com/undabet/assets/jetx/PlinkoX.webp",
          image: "https://storage.googleapis.com/undabet/assets/jetx/PlinkoX.webp",
          GameCategory: "GamesMobile",
          has_demo: 1,
        },
        {
          game_id: "Cappadocia",
          GameName: "Cappadocia",
          FullGameName: "Cappadocia",
          GameIcon: "https://storage.googleapis.com/undabet/assets/jetx/Cappadocia.webp",
          image: "https://storage.googleapis.com/undabet/assets/jetx/Cappadocia.webp",
          GameCategory: "GamesMobile",
          has_demo: 1,
        },
      ],
      pragmatic: [
        {
          "id": 62,
          "game_id": "vs20sbxmas",
          "game_name": "Sweet Bonanza Xmas",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs20sbxmas.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs20sbxmas%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20sbxmas&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 481,
          "game_id": "1301",
          "game_name": "Spaceman",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/1301.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3D1301%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=1301&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 122,
          "game_id": "vs20porbs",
          "game_name": "Santa's Great Gifts",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs20porbs.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs20porbs%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20porbs&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 107,
          "game_id": "vswaysrockblst",
          "game_name": "Rocket Blast Megaways",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vswaysrockblst.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvswaysrockblst%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vswaysrockblst&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 468,
          "game_id": "vs20dhcluster",
          "game_name": "Twilight Princess",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs20dhcluster.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs20dhcluster%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20dhcluster&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 193,
          "game_id": "vs12bbbxmas",
          "game_name": "Bigger Bass Blizzard - Christmas Catch",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs12bbbxmas.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs12bbbxmas%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs12bbbxmas&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 64,
          "game_id": "vs20starlightx",
          "game_name": "Starlight Princess 1000",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs20starlightx.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs20starlightx%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20starlightx&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 59,
          "game_id": "vs20starlight",
          "game_name": "Starlight Princess",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs20starlight.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs20starlight%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20starlight&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
        {
          "id": 621,
          "game_id": "vs20sugrux",
          "game_name": "Sugar Rush Xmas",
          "provider_id": 5,
          "provider_name": "Pragmatic Play",
          "image": "https://api-dk11.pragmaticplay.net/game_pic/rec/325/vs20sugrux.png",
          "category_name": "Popular",
          "category_id": 9,
          "launch_type": 2,
          "launch_url": "https://api-dk11.pragmaticplay.net/gs2c/playGame.do?key=token%3Dprofile_token%26symbol%3Dvs20sugrux%26technology%3DH5%26platform%3DMOBILE%26language%3Den%26cashierUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fdeposit%26lobbyUrl%3Dhttps%3A%2F%2Fsportycrash.com%2Fcasino&stylename=sprksmtrx_maybets",
          "url_type": 3,
          "demo_url": "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20sugrux&lang=en&cur=KES&jurisdiction=99&lobbyUrl=https://sportycrash.com/casino&stylename=sprksmtrx_maybets",
          "has_demo": 1,
          "others": "",
          "launch_parameters": {
            "start_game_url": "",
            "parameters": null
          }
        },
      ]
    }
  },
  components: {

  },
  mounted() {
    this.$store.dispatch("setCurrentPage", 'home');
    this.$store.dispatch("setSportID", 1);

  },
  methods: {

    closeModal: function () {

      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add('d-none');
    },

    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "home-games-id-" + index + "-");
    },

    getDivID: function (gameID) {

      return "home-game-id-" + gameID;
    },

    getDivIDWithHash: function (gameID) {

      return "#home-game-id-" + gameID;
    },

    getImg: function (img) {

      return img;//.replaceAll("/rec/325/","/square/200/")

    },

    getReference: function (game_id) {

      return '#crash-' + game_id;

    },
    getID: function (game_id) {

      return 'crash-' + game_id;

    },
    showButtons(game) {
      this.showButtonsForGame = game.game_id;
    },
    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getLaunchURL1: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;
      var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;
      tokn = accountId + '' + tokn
      launch_url = launch_url.replaceAll("profile_token", tokn);

      return launch_url;

    },

  }
}
</script>

<style scoped>
.row {
  row-gap: .5em;
}

.crash-game {
  padding: .2em;
}

.fly-game{
  text-align: center;
}

.app {
  height: 100% !important;
}

.rocket-fastball {
  height: 200px;
}

h5 {
  font-size: 1rem;
}

.rocket-fastball{
  height: 138px;
  width: 138px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}
</style>